html,
body,
#root {
  height: 100%;
}

@font-face {
  font-family: "Lazare Grotesk";
  src: url("fonts/Lazare/LazareGrotesk-Bold.woff2") format("woff2"),
    url("fonts/Lazare/LazareGrotesk-Bold.woff") format("woff"),
    url("fonts/Lazare/LazareGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Universal Sans Text 700";
  src: url("fonts/Universal-Sans-Text/UniversalSansText-700.woff2")
      format("woff2"),
    url("fonts/Universal-Sans-Text/UniversalSansText-700.woff") format("woff"),
    url("fonts/Universal-Sans-Text/UniversalSansText-700.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Universal Sans Text";
  src: url("fonts/Universal-Sans-Text/UniversalSansText-600.woff2")
      format("woff2"),
    url("fonts/Universal-Sans-Text/UniversalSansText-600.woff") format("woff"),
    url("fonts/Universal-Sans-Text/UniversalSansText-600.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Universal Sans Text";
  src: url("fonts/Universal-Sans-Text/UniversalSansText-500.woff2")
      format("woff2"),
    url("fonts/Universal-Sans-Text/UniversalSansText-500.woff") format("woff"),
    url("fonts/Universal-Sans-Text/UniversalSansText-500.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Universal Sans Text";
  src: url("fonts/Universal-Sans-Text/UniversalSansText-400.woff2")
      format("woff2"),
    url("fonts/Universal-Sans-Text/UniversalSansText-400.woff") format("woff"),
    url("fonts/Universal-Sans-Text/UniversalSansText-400.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
